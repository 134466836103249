import { userStore } from "../store/user.store";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { AUTH_MUTATION } from "../api/graphql/auth";

export const commonLogout = () => {
  localStorage.removeItem("token");
  userStore(undefined);
};

export const useAuth = () => {
  const navigate = useNavigate();
  const [doLogin, { loading }] = useMutation(AUTH_MUTATION);
  const login = async (username: string, password: string) => {
    const d = await doLogin({ variables: { username, password } });

    if (d?.data?.auth) {
      userStore({ ...d?.data?.auth, token: d?.data?.auth?.accessToken });
      localStorage.setItem("token", d?.data?.auth?.accessToken);
      navigate("/");
    }
  };

  const logout = () => {
    commonLogout();
    navigate("/login");
  };

  return { login, logout, authRequestLoading: loading };
};
