import { gql } from "@apollo/client";

export const AUTH_MUTATION = gql`
  mutation auth($username: String, $password: String) {
    auth(username: $username, password: $password) {
      accessToken
      expiresIn
      refreshToken
      tokenType
    }
  }
`;