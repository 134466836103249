import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Link, matchPath, useLocation, useMatch } from "react-router-dom";
import { CustomButton } from "../customButton";
import { useAuth } from "../../hooks/useAuth";

import Door from "../../assets/icons/exit.svg?react";
import Home from "../../assets/icons/home.svg?react";
import Chrome from "../../assets/icons/chrome.svg?react";
import Store from "../../assets/icons/store.svg?react";
import ArrowDown from "../../assets/icons/arrowDown.svg?react";
import Promotion from "../../assets/icons/promotion.svg?react";
import Ruble from "../../assets/icons/ruble.svg?react";
import Sales from "../../assets/icons/sales.svg?react";
import Garage from "../../assets/icons/garage.svg?react";
import Skyscraper from "../../assets/icons/skyscraper.svg?react";
import Price from "../../assets/icons/price.svg?react";

import "../../styles/components/layouts/sidebar.css";

const links = [
  {
    name: "Главная",
    link: "/",
    icon: Home,
  },
  {
    name: "Компании",
    link: "/companies",
    icon: Skyscraper,
  },
  {
    name: "Магазины",
    link: "/stores",
    icon: Store,
  },
  {
    name: "Расширение Chrome",
    link: "/chrome-ext",
    icon: Chrome,
  },
  {
    name: "Продвижение",
    icon: Promotion,
    children: [
      {
        name: "SKU",
        icon: Ruble,
        link: "/skus",
      },
    ],
  },
  {
    name: "Акции",
    icon: Sales,
    children: [
      {
        name: "Сток",
        icon: Garage,
        link: "/stock",
      },
      {
        name: "Цены на товары",
        icon: Ruble,
        link: "/prices",
      },
      {
        name: "Установка цены в акциях",
        icon: Price,
        link: "/suggestions",
      },
    ],
  },
];

const LinkSidebarItem: FC<(typeof links)[0] & { isActive: boolean }> = ({
  name,
  link,
  isActive,
  icon: Icon,
}) => {
  return (
    <Link
      className={`main-sidebar-item main-sidebar-item-active__${!!isActive}`}
      to={link}
    >
      {Icon && <Icon className="main-sidebar-item-icon" />}
      <span>{name}</span>
    </Link>
  );
};

const SidebarItem: FC<(typeof links)[0]> = ({
  name,
  link,
  icon: Icon,
  children,
}) => {
  const childrenRef = useRef<HTMLDivElement>(null);
  const [collapse, setCollapse] = useState(false);
  const isPathActive = !!useMatch(link || { path: "nonpath" });
  const { pathname } = useLocation();

  const childrenLinkActive = useMemo(() => {
    if (!children?.length) return "";
    return (
      children.find((i) => matchPath({ path: i.link, end: false }, pathname))
        ?.link || ""
    );
  }, [children, pathname]);

  const setHeight = (height: number) => {
    childrenRef.current.style.cssText = `--scroll-height: ${height + "px"}`;
  };

  useEffect(() => {
    if (childrenLinkActive && !collapse) {
      setHeight(childrenRef.current.scrollHeight);
      setCollapse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (children?.length) {
    return (
      <div
        className={`main-sidebar-item-collapse-wrapper main-sidebar-item-collapse-wrapper-active__${collapse || childrenLinkActive}`}
      >
        <div
          onClick={() => {
            setHeight(collapse ? 0 : childrenRef.current.scrollHeight);
            setCollapse((p) => !p);
          }}
          className="main-sidebar-item-collapse"
        >
          {Icon && <Icon className="main-sidebar-item-icon" />}
          <span className="truncate">{name}</span>
          <ArrowDown className="main-sidebar-item-arrow-down" />
        </div>
        <div
          // @ts-ignore
          style={{ "--scroll-height": 0 + "px" }}
          ref={childrenRef}
          className={`main-sidebar-item-children main-sidebar-item-children-collapsed__${collapse}`}
        >
          {children.map(({ name: cName, icon: CIcon, link: cLink }) => {
            return (
              <LinkSidebarItem
                key={cLink}
                link={cLink}
                isActive={childrenLinkActive === cLink}
                name={cName}
                icon={CIcon}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <LinkSidebarItem
      link={link}
      isActive={isPathActive}
      name={name}
      icon={Icon}
    />
  );
};

export const Sidebar = () => {
  const { logout } = useAuth();

  return (
    <div className="main-sidebar">
      <Link to="/" className="main-sidebar-logo">
        EOG APP
      </Link>
      <div className="flex flex-col gap-8 overflow-auto mb-16">
        {links.map((i, idx) => (
          <SidebarItem key={idx} {...i} />
        ))}
      </div>

      <CustomButton leftIcon={Door} className="mt-auto" onClick={logout}>
        Выйти
      </CustomButton>
    </div>
  );
};
